import React from 'react';
import "styles/theme.css";
import { useParams } from "react-router-dom";
import { clubs } from './clubs';
import Events from './events';

const Club = (props) => {
    let { id } = useParams();
    let club = clubs[id];

    return (
        <Events club={club}/>
    )
}

export default Club;
