import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import "styles/theme.css";
import Header from 'components/header';
import Footer from 'components/footer';
import { Container, Row, Col, Table, Form, InputGroup } from 'react-bootstrap';
import { FaExternalLinkAlt, FaSearch } from 'react-icons/fa';
import getBackend from 'backend/backend';
import { clubsByState, stateByCity } from 'pages/clubs';

const Events = (props) => {
    let club = props.club;

    const [shows, setShows] = useState([]);
    const [filteredShows, setFilteredShows] = useState([]);
    const [monthFilter, setMonthFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('Available');
    const [venueFilter, setVenueFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const currentDate = new Date().toISOString().split('T')[0];

    const venues = club.name ? [] : Object.values(clubsByState[stateByCity[club.city]])
        .map(c => `${c.club.name}, ${c.club.location}`);

    useEffect(() => {
        let canceled = false;

        const getShows = async () => {
          const result = await getBackend().getShowEvents(club.name, club.city, currentDate);
          if (canceled)
            return;

          if (result.error == null) {
            setShows(result.response.show_events);
            setFilteredShows(result.response.show_events);
          } else {
            setShows([]);
            setFilteredShows([]);
          }
        };

        getShows();

        return async () => {
          canceled = true;
        }
    }, [club, currentDate]);


    useEffect(() => {
        let result = shows;

        if (monthFilter) {
            result = result.filter(show => {
                const showDate = new Date(show.date);
                const showMonth = showDate.toLocaleString('default', { month: 'long' });
                return showMonth === monthFilter;
            });
        }

        if (statusFilter) {
            result = result.filter(show => show.status === statusFilter);
        }

        if (venueFilter) {
            result = result.filter(show => `${show.club}, ${show.club_location}` === venueFilter);
        }

        if (searchTerm) {
            result = result.filter(show => 
                (show.date && show.date.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (show.title && show.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (show.comedian_name && show.comedian_name.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }

        setFilteredShows(result);
    }, [shows, monthFilter, statusFilter, searchTerm, venueFilter]);

    const getUniqueMonths = () => {
        const months = [...new Set(shows.map(show => {
            const date = new Date(show.date);
            return date.toLocaleString('default', { month: 'long' });
        }))];
        return months.sort((a, b) => new Date(a + ' 1, 2000') - new Date(b + ' 1, 2000'));
    };

    return (
        <>
            <Helmet>
                <title>PunchHub.ai {club.name} | {club.description}</title>
                <meta name="description" content={`PunchHub.ai ${club.name} | ${club.description}`} />
            </Helmet>
            <Header/>
            
            <Container fluid className="py-4">
                <Row className="mb-4 justify-content-center min-vh-100">
                <Col xs={12} sm={12} md={10} lg={9} xl={9} xxl={8}>
                <div className="d-flex align-items-center mb-4">
                    <h2 className="mb-0 me-2">{club.name} Shows | {club.description}</h2>
                    <a href={club.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                        <FaExternalLinkAlt size={20} />
                    </a>
                </div>
                <div className="mb-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                        <InputGroup className="mb-2 mb-md-0" style={{width: 'auto'}}>
                            <InputGroup.Text>
                                <FaSearch />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                onChange={(e) => setSearchTerm(e.target.value)}
                                size="sm"
                                style={{width: '150px'}}
                            />
                        </InputGroup>
                        <div className="d-flex">
                            <Form.Select 
                                onChange={(e) => setMonthFilter(e.target.value)} 
                                value={monthFilter} 
                                className="me-2"
                                size="sm"
                                style={{width: '150px'}}
                            >
                                <option value="">All Months</option>
                                {getUniqueMonths().map(month => (
                                    <option key={month} value={month}>{month}</option>
                                ))}
                            </Form.Select>
                            <Form.Select 
                                onChange={(e) => setStatusFilter(e.target.value)} 
                                value={statusFilter}
                                size="sm"
                                style={{width: '150px'}}
                            >
                                <option value="">All Statuses</option>
                                <option value="Available">Available</option>
                                <option value="Sold Out">Sold Out</option>
                            </Form.Select>
                            {!club.name &&
                            <Form.Select
                                onChange={(e) => setVenueFilter(e.target.value)}
                                value={venueFilter}
                                className="me-2"
                                size="sm"
                                style={{width: '150px'}}
                            >
                                <option value="">All Venues</option>
                                {venues.map(venue => (
                                    <option key={venue} value={venue}>{venue}</option>
                                ))}
                            </Form.Select>
                            }
                        </div>
                    </div>
                </div>
                {filteredShows.length > 0 ? (
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Show</th>
                                <th>Ticket Status</th>
                                <th>Comedian Profile</th>
                                {!club.name &&
                                    <th>Venue</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {filteredShows.map((show, index) => (
                                <tr key={index}>
                                    <td>{show.date}<br/><span className="text-muted">{show.time}</span></td>
                                    <td>{show.title}</td>
                                    <td>
                                        {show.status === 'Available' ? (
                                            <a href={show.ticket_link} className="btn btn-primary btn-sm">
                                                <span className="status-bullet status-available"></span>
                                                Buy Tickets
                                            </a>
                                        ) : (
                                            <a href={show.ticket_link} className="btn btn-neutral btn-sm">
                                                <span className="status-bullet status-available"></span>
                                                {show.status}
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        {show.punchhub_link && (
                                            <a href={show.punchhub_link}>
                                                {show.comedian_name}
                                            </a>
                                        )}
                                    </td>
                                    {!club.name &&
                                    <td>
                                        <span>
                                            {show.club}, {show.club_location}
                                        </span>
                                    </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No shows available at the moment. Please check back later.</p>
                )}
                </Col>
                </Row>
            </Container>

            <Footer/>
        </>
    );
}

export default Events;
