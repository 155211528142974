import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';

import "styles/theme.css";
import Header from 'components/header';
import Footer from 'components/footer';
import { Container, Row, Col } from 'react-bootstrap';
import { Paths } from 'paths';

export const clubs = {
    "comedymothership": {
        "name": "Comedy Mothership",
        "description": "Comedy Mothership, Joe Rogan's Comedy Club, Austin, TX",
        "link": "https://comedymothership.com/",
        "location": "Austin, TX"
    },
    "creekandcave": {
        "name": "Creek and Cave",
        "description": "The Creek and The Cave, Austin, TX",
        "link": "https://www.creekandcave.com/#shows",
        "location": "Austin, TX"
    },
    "austincitylimitsliveatthemoodytheater": {
        "name": "Austin City Limits Live at The Moody Theater",
        "description": "Austin City Limits Live at The Moody Theater, Austin, TX",
        "link": "https://www.acl-live.com/",
        "location": "Austin, TX"
    },
    "bassconcerthall": {
        "name": "Bass Concert Hall",
        "description": "Bass Concert Hall, Austin, TX",
        "link": "https://texasperformingarts.org/venues/bass-concert-hall",
        "location": "Austin, TX"
    },
    "bassconcerthallparking": {
        "name": "Bass Concert Hall Parking",
        "description": "Bass Concert Hall Parking, Austin, TX",
        "link": "https://texasperformingarts.org/visit/parking-maps",
        "location": "Austin, TX"
    },
    "capcitycomedyclub": {
        "name": "Cap City Comedy Club",
        "description": "Cap City Comedy Club, Austin, TX",
        "link": "https://www.capcitycomedy.com/",
        "location": "Austin, TX"
    },
    "comeandtakeitlive": {
        "name": "Come and Take It Live",
        "description": "Come and Take It Live, Austin, TX",
        "link": "https://www.comeandtakeitlive.com/",
        "location": "Austin, TX"
    },
    "eastaustincomedyclub": {
        "name": "East Austin Comedy Club",
        "description": "East Austin Comedy Club, Austin, TX",
        "link": "https://www.eastaustincomedy.com/",
        "location": "Austin, TX"
    },
    "fallouttheater": {
        "name": "Fallout Theater",
        "description": "Fallout Theater, Austin, TX",
        "link": "https://fallouttheater.com/",
        "location": "Austin, TX"
    },
    "paramounttheatreaustin": {
        "name": "Paramount Theatre Austin",
        "description": "Paramount Theatre, Austin, TX",
        "link": "https://www.austintheatre.org/",
        "location": "Austin, TX"
    },
    "aratanitheatre": {
        "name": "Aratani Theatre",
        "description": "Aratani Theatre, Los Angeles, CA",
        "link": "https://www.jaccc.org/aratani-theatre",
        "location": "Los Angeles, CA"
    },
    "icehousecomedyclub": {
        "name": "Ice House Comedy Club",
        "description": "Ice House Comedy Club, Pasadena, CA",
        "link": "https://icehousecomedy.com/",
        "location": "Pasadena, CA"
    },
    "improvcomedyclubbrea": {
        "name": "Improv Comedy Club - Brea",
        "description": "Improv Comedy Club, Brea, CA",
        "link": "https://improv.com/brea/",
        "location": "Brea, CA"
    },
    "improvcomedyclubirvine": {
        "name": "Improv Comedy Club - Irvine",
        "description": "Improv Comedy Club, Irvine, CA",
        "link": "https://improv.com/irvine/",
        "location": "Irvine, CA"
    },
    "largoatthecoronet": {
        "name": "Largo at the Coronet",
        "description": "Largo at the Coronet, Los Angeles, CA",
        "link": "https://largo-la.com/",
        "location": "Los Angeles, CA"
    },
    "laughfactoryhollywood": {
        "name": "Laugh Factory Hollywood",
        "description": "Laugh Factory, Hollywood, CA",
        "link": "https://laughfactory.com/clubs/hollywood",
        "location": "Los Angeles, CA"
    },
    "laughfactorylongbeach": {
        "name": "Laugh Factory Long Beach",
        "description": "Laugh Factory, Long Beach, CA",
        "link": "https://laughfactory.com/clubs/long-beach",
        "location": "Long Beach, CA"
    },
    "longbeachconventioncenterterrace": {
        "name": "Long Beach Convention Center - Terrace Theater",
        "description": "Long Beach Convention Center - Terrace Theater, Long Beach, CA",
        "link": "https://www.longbeachcc.com/terrace-theater/",
        "location": "Long Beach, CA"
    },
    "lyrichyperiontheatre": {
        "name": "Lyric Hyperion Theatre",
        "description": "Lyric Hyperion Theatre, Los Angeles, CA",
        "link": "https://lyrichyperion.com/",
        "location": "Los Angeles, CA"
    },
    "ontarioimprov": {
        "name": "Ontario Improv Comedy Club",
        "description": "Ontario Improv Comedy Club, Ontario, CA",
        "link": "https://improv.com/ontario/",
        "location": "Ontario, CA"
    },
    "orpheumtheatrelosangeles": {
        "name": "Orpheum Theatre - Los Angeles",
        "description": "Orpheum Theatre, Los Angeles, CA",
        "link": "https://www.laorpheum.com/",
        "location": "Los Angeles, CA"
    },
    "standupcomedyclubbellflower": {
        "name": "Stand Up Comedy Club - Bellflower",
        "description": "Stand Up Comedy Club, Bellflower, CA",
        "link": "https://standupcomedybellflower.com/",
        "location": "Bellflower, CA"
    },
    "sunsetrooftop": {
        "name": "Sunset Rooftop",
        "description": "Sunset Rooftop, Los Angeles, CA",
        "link": "https://www.sunsetrooftop.com/",
        "location": "Los Angeles, CA"
    },
    "thecomedystorelosangeles": {
        "name": "The Comedy Store - Los Angeles",
        "description": "The Comedy Store, Los Angeles, CA",
        "link": "https://thecomedystore.com/",
        "location": "Los Angeles, CA"
    },
    "groundlingstheatre": {
        "name": "The Groundlings Theatre and School",
        "description": "The Groundlings Theatre and School, Los Angeles, CA",
        "link": "https://www.groundlings.com/",
        "location": "Los Angeles, CA"
    },
    "labathollywoodimprov": {
        "name": "The Lab at Hollywood Improv",
        "description": "The Lab at Hollywood Improv, Los Angeles, CA",
        "link": "https://improv.com/hollywood/",
        "location": "Los Angeles, CA"
    },
    "packtheater": {
        "name": "The Pack Theater",
        "description": "The Pack Theater, Los Angeles, CA",
        "link": "https://www.packtheater.com/",
        "location": "Los Angeles, CA"
    },
    "ucbannex": {
        "name": "UCB Annex",
        "description": "UCB Annex, Los Angeles, CA",
        "link": "https://ucbcomedy.com/",
        "location": "Los Angeles, CA"
    },
    "uprightcitizensbrigadetheatre": {
        "name": "Upright Citizens Brigade Theatre",
        "description": "Upright Citizens Brigade Theatre, Los Angeles, CA",
        "link": "https://ucbcomedy.com/",
        "location": "Los Angeles, CA"
    }
};

export const cityByState = {
    "CA": "Los Angeles",
    "TX": "Austin"
};

export const toCityID = (input) => {
    return input.toLowerCase().replace(/\s+/g, '-');
}

export const stateByCity = {
    "Los Angeles, CA": "CA",
    "Austin, TX": "TX"
};

export const clubsByState = Object.entries(clubs).reduce((acc, [key, club]) => {
    const state = club.location.split(', ')[1];
    if (!acc[state]) acc[state] = [];
    acc[state].push({key, club});
    return acc;
}, {});

const Clubs = () => {

    return (
        <>
            <Helmet>
                <title>Comedian Clubs | {Consts.ProjectName}</title>
                <meta name="description" content="Comedian clubs" />
            </Helmet>
            <Header/>

            <Container className="d-flex justify-content-center min-vh-100">
                <Row>
                    <Col>
                        {(() => {

                            return (
                                <>
                                    <h4>Featured Venues</h4>
                                    <Row>
                                    {Object.entries(clubsByState).map(([state, stateClubs]) => (
                                        <Col key={state}>
                                            <h5>{cityByState[state]}</h5>
                                            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                                {stateClubs.map(({key, club}) => (
                                                    <li key={key}>
                                                        <a href={`${Paths.Club.path}/${key}`} style={{ textDecoration: 'underline' }}>
                                                            {club.description}
                                                        </a>
                                                    </li>
                                                ))}

                                                <li key="all">
                                                    <a href={`${Paths.Clubs.path}/${toCityID(cityByState[state])}`} style={{ textDecoration: 'underline' }}>
                                                        All venues near {cityByState[state]}
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    ))}
                                    </Row>
                                </>
                            );
                        })()}
                        
                    </Col>
                </Row>
            </Container>

            <Footer/>
        </>
    );
}

export default Clubs;