import React from 'react';
import "styles/theme.css";
import Events from './events';

const EventsByCity = (props) => {
    let club = {name: "", description: props.city, city: props.city, link: null}

    return (
        <Events club={club}/>
    )
}

export default EventsByCity;
